@import "tailwindcss/base";
/* ----- Custom base styles ----- */
@import "./base.css";

@import "tailwindcss/components";
/* ----- Custom components ----- */
@import "./components.css";

@import "tailwindcss/utilities";
/* ----- Custom utilities ----- */

.transitioning {
    transition: .3s ease all;
}

body, main, html{
    scroll-behavior: smooth;
}

/* Site CSS */

/* Global */
h1 {
    @apply relative text-secondary;
}
h2{
    @apply text-secondary
}
a {
    @apply inline-block;
}


header img {
    filter: drop-shadow(2px 2px 2px #b6b6b6);
}

a:hover {
    @apply cursor-pointer;
}

.bouton, input[type=submit] {
    @apply relative inline-block px-4 py-2 bg-primary text-white cursor-pointer;
}

.bouton:hover, input[type=submit]:hover {
    @apply bg-secondary-500 border-secondary border border-solid text-white;
}

input:focus, textarea:focus {
    outline: none;
}

.bords {
    @apply border border-solid p-4;
}

/* Colorbox */
.colorbox {
    box-shadow: 0px 0px 8px #333;
}

/* Formulaire */
input[type=text], input[type=email], input[type=password], input[type=tel], input[type=number], textarea {
    @apply shadow-md border-none bg-white p-2 rounded;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, input[type=tel]:focus, input[type=number]:focus, textarea:focus {
    @apply outline-none;
}

.form {
    @apply relative block;
}

.form input[type=text] + label, .form input[type=email] + label, .form input[type=tel] + label, .form input[type=number] + label, .form input[type=password] + label {
    position: absolute;
    left: 3%;
    top: 1.25rem;
    pointer-events: none;
    width: 100%;
    transform: translateY(-50%);
    transition: transform 200ms ease;
    color: #9a9a9a;
    text-align: left;
}

.form input[type=text]:focus + label, .form input[type=email]:focus + label, .form input[type=tel]:focus + label, .form input[type=number]:focus +label, .form input[type=password]:focus + label, .form textarea:focus ~ label, .form label.freeze {
    @apply text-secondary-500 font-normal text-xs !important;
    transform: translateY(-1.5rem) !important;
}

/* Cadres */

#checkboxes label{
    @apply flex items-center gap-1 w-[50%] lg:w-[30%] text-sm xs:text-base;
    & img{
        @apply w-8 h-6
      }
}
.box-ligne {
    @apply relative border-b border-solid;
}

.card {
    @apply bg-white shadow-xl mb-4 py-8 px-12;
}

.fil-ariane {
    @apply flex flex-row items-center flex-wrap mb-8;

    & a{
        @apply relative mr-8 hover:text-secondary;

        &:not(last-of-type)::after{
            content:"\f0da";
            @apply text-secondary mx-auto w-4 h-4 absolute -right-8 y-center;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
        }

        &:last-of-type{
            @apply text-secondary font-bold;

            &:after{
                @apply hidden;
            }
        }
    }


}

/* no ui slider */
#filter {
    .noUi-horizontal {
        height: 10px;
    }
    .noUi-connect {
        @apply bg-secondary;
    }
    .noUi-handle {
        width: 20px;
        height: 20px;
    &::after,
    &::before {
         content: none;
     }
    }
}

option, select{
    @apply text-lg;
}

input.view-range{
    @apply inline bg-transparent text-secondary;
}

.ui-slider{
    @apply border-0;
}
.ui-slider-range{
    @apply bg-secondary;
}

.ui-widget-content {
    border: 1px solid #dddddd;
    background: #ffffff;
    color: #333333;
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1em;
    height: 1em;
    cursor: pointer;
    touch-action: none;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -0.3em;
}

 #slider-budget{
    border: none;
    text-align: center;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 1px solid #c5c5c5;
    background-color: #ffffff;
    font-weight: normal;
    color: #454545;
}

.ui-slider-handle:focus{
    @apply bg-white outline-none;
    border: 1px solid #c5c5c5;
    background-color: #ffffff;
    font-weight: normal;
    color: #454545;
}
.ui-slider-handle:active{
    @apply bg-white;
}

.ui-slider-horizontal {
    height: 0.5em;
}

/* Header */
header {
    @apply relative text-center bg-primary z-10;
}

header ul, header ul li {
    @apply relative list-none p-0 m-0;
}

header a {
    @apply text-black transition-all inline-block py-4;
    font-size: 1.25rem;
    //text-shadow: 2px 2px 4px #9b9b9b;
}

header a.nav-active,
header a:hover {
    @apply bg-white text-secondary;
}

header ul.subnav {
    @apply bg-white;
}

header ul.subnav li a {
    @apply block bg-blue;
}


/* Contenu */
#main {
    @apply relative;
}

#content {
    @apply px-4 py-8;

    & ul {
        @apply list-disc list-inside mb-4;
    }
}

#slider-hero::before,
#page-hero::before {
    content: "";
    background-image: url("/img/bg/fond-hero.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: cover;
    opacity: 0.7;
    top: 0;
    left: 0;
    z-index: 2;
    width: 160%;
    height: 100%;
}

#slider-hero::before{
    filter: invert(1) brightness(1.5);
    @screen lg{
        opacity: 1;
    }
}

.img-article {
    @apply overflow-hidden relative;
    transition: 0.9s ease-out;
    & .overlay{
        @apply opacity-0 -z-1 absolute w-full h-full abs-center flex justify-center items-center bg-transparent;
    }

    & img{
        transition: 0.3s ease-out;
    }

    &:hover{
        & img {
            transform:scale(1.1);
            transition: 0.7s ease-out;
        }

        & .overlay{
            @apply opacity-100 z-1;

            & a{
                &:hover{
                    @apply bg-white border-white transition-all;
                    box-shadow: 1px 1px 1000px 1000px rgba(0, 0, 0, 0.20);
                }
            }
        }
    }
}

#slider-vehicule-main{
    @screen lg{
        height: 33rem;
    }
}

.info-article::before {
    content: "";
    background-image: url("/img/bg/fond-info.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
}

#presentation h2,
#espace-moto h2{
    @apply relative;
}

#presentation>div{
    height: 24rem;
}
#achat {
    background-color: #eeeeee
}

#achat::before, #contactez::before {
    content: "";
    background-image: url("/img/bg/fond-section.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}


.fond-gris {
    background-color: #EEEEEE;
}

.fond-corner::after {
    content: "";
    background-image: url("/img/bg/fond-corner.png");
    background-repeat: no-repeat;
    position: absolute;
    background-position: bottom right;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

/* PAGE NOS VEHICULES */

summary{
    @apply w-5 h-5 rounded-full text-secondary text-lg cursor-pointer flex justify-center font-bold items-center mb-2 bg-transparent border border-secondary p-1
}


.cardVehicule {
    box-shadow: -3px 9px 17px -7px rgba(0, 0, 0, 0.1);
}

.containerCardCarre {
    display: grid !important;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.cardCarre:hover .containerInfo {
    @apply opacity-100 transition-all;
}

.cardCarre:hover .containerImg {
    @apply brightness-50 transition-all;
}

#hero {
    @apply relative grid place-items-center w-full z-5;

    &::before {
        content: '';
        @apply absolute left-0 top-0 w-full h-full bg-white bg-opacity-50 z-1;
        backdrop-filter: blur(5px);
    }

    & h1 {
        @apply py-24 lg:py-40 z-1 text-6xl text-center lg:w-1/2 font-bold text-gray-800;
        //text-shadow: 2px 2px 4px #9b9b9b;
    }

    & img {
        @apply absolute left-0 top-0 w-full h-full object-cover;
    }
}

/* Détail véhicule */
#slider-vehicule-thumb {
    & .swiper-slide {
        @apply opacity-75 border-2 border-transparent;
        &-active {
            @apply opacity-100 border-primary;
        }
    }
}

/* Contact */
#page-hero {
    @apply bg-cover;
    & h1{
        @apply text-4xl text-center md:text-left sm:text-5xl font-bold mb-12 text-white;

    }
}
#mentions h1,
#politique h1{
    @apply text-3xl sm:text-4xl text-primary font-bold mb-12 mx-auto w-full ;
}

#mentions h2,
#politique h2{
    @apply relative w-full text-2xl text-secondary-900 font-bold mt-6 mb-4;
}

.page-type {
    @apply py-12 xl:pt-8 xl:pb-28;
    background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)),
    url('/img/bg/fond-section.png') no-repeat left top;
    background-size: 50vh;

    & h2{
        @apply text-2xl sm:text-3xl xl:text-3xl xxl:text-4xl text-primary font-bold mb-12 mx-auto w-11/12 md:w-4/5 xxl:w-2/3 ;
    }

    & .text-content{
        @apply w-11/12 md:w-4/5 xxl:w-2/3 mx-auto;
    }

    & h3{
        @apply relative text-2xl text-secondary-900 font-bold mb-8;
    }

    & p{
        @apply text-base xxl:text-lg text-gray-700;
    }

    & label{
        @apply text-base xxl:text-lg relative;
        width: fit-content;

        &.required::after{
            content:"*";
            @apply text-primary absolute -right-2 h-2 w-2 top-0;
        }

        &[for=contact_form_rgpd]{
            @apply text-sm;
            &:after{
                content:"";
                @apply hidden;
            }
        }
    }

    & a:not(.btn){
        @apply text-secondary hover:text-secondary-300;
    }

    & textarea{
        @apply h-40 w-full max-w-full;
    }

    & input:not(.btn){
        @apply w-full;
    }
}

#services>div:nth-child(2n+1) .text-content::before,
#apropos .text-content::before{
    content: "";
    background: url("/img/bg/trait-vehicule.svg") top left no-repeat;
    background-size: contain;
    @apply absolute -top-5px left-0
    bg-auto w-full h-10;
}

#liste-actu .text-content::before{
    content: "";
    background: url("/img/bg/trait-vehicule.svg") top left no-repeat;
    background-size: contain;
    @apply absolute -top-5px x-center bg-auto w-[600px] h-10 lg:block hidden;
}
#services>div:nth-child(2n) .text-content::before {
    content: "";
    background: url("/img/bg/trait-vehicule.svg") top left no-repeat;
    background-size: contain;
    @apply absolute -top-8 left-0
    bg-auto w-full h-10 rotate-180;
}

#services .page-type:not(:first-child){
    background: none;
    background-color: #E5E5E5;
}

#services .service{
    &:nth-child(2n){
         @apply bg-white;
        & .text{
              background-color: #E5E5E5;
          }
        & .text-content>div:not(.text){
              @apply xl:-mr-12
          }
        & h2{
              @apply text-right
          }
     }
    &:nth-child(2n+1):not(:first-child){
         background-color: #E5E5E5;
        & .text{
              background-color: #fff;
          }
        & .text-content>div:not(.text){
              @apply xl:-ml-12
          }
    }
}
#espace-moto.page-type::before,
#espace-moto.page-type::after{
    content:"";
    @apply hidden xl:block;
}

#espace-moto.page-type::before{
    content:"";
    @apply -top-20 -left-20;
}
#espace-moto.page-type::after{
    content:"";
    @apply  -bottom-16 -right-16 -z-1;
}

#contact-rachat{

    &:after{
        content:'';
        @apply z-0;
    }

    & h2{
        @apply relative pt-0;
    }

}
#contact {
    & p, a{
        @apply !text-base font-bold;
    }

    & a:hover{
        @apply text-secondary;
    }

    label[for=contact_form_rgpd] a{
        @apply text-sm;
    }
}

#contact_form_rgpd{
    @apply border border-solid border border-primary rounded h-5 w-5 mt-1;
}

.card-actu, #presentation a{
    & img{
          transition: 0.7s ease-out;
      }
}

.card-actu:hover, #presentation a:hover{
    & img{
        transform: scale(1.2);
      }
}
.card-actu .gradient{
    @apply absolute top-0 left-0 w-full h-full;
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 80%);
}
.cardActu{

    & .btn {
        @apply bg-transparent border-primary border-solid border text-primary hover:bg-primary hover:text-white;
    }

    &:hover{
        @apply cursor-pointer;

        & .overlay{
            @apply opacity-100 justify-center items-center top-0 x-center z-1 h-full;
            background: rgba(255,255,255,0.5);
            backdrop-filter: blur(10px);
            transition: 0.3s ease-in-out;
        }
    }

}
.cardActu-img{

    @apply overflow-hidden relative;

    & .overlay{
        @apply opacity-0 flex justify-center items-center -top-64 x-center -z-1 h-full bg-transparent absolute;
        width: 101%;
    }
    & img{
        @apply object-cover object-center;
        aspect-ratio: 1/1;
    }

}
.titreActu {
    & h1{
        @apply mb-8;
    }

    & span{
        @apply font-bold text-secondary;
    }

    & p{
        @apply text-secondary-900;
    }
}

.historique{
    & #timeline {
        & #line {
              position: absolute;
              left: 50%;
              width: 2px;
              height: 100%;
              z-index: 10;
              top: 0;
            @apply bg-secondary opacity-25 rounded;
          }
        }
}

.leading-1{
    line-height: 1;
}
#equipe{
    & .equipe-n1::before{
        content: "";
        background: url("/img/bg/trait-presentation.png") no-repeat;
        @apply absolute top-[0.70rem] -left-4 xl:-left-8 -z-1 w-full h-[20px] rotate-180 bg-right
      }
    & .equipe-n1::after{
          content: "";
            background-color: #bdc9d9;

          @apply absolute top-[1.2rem] -left-4 xl:-left-8  w-full h-[2px] bg-right
      }
}

/* Footer */

#pre-footer::before {
    content: "";
    background-image: url("/img/bg/fond-footer.png");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    background-size: cover;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#footer {
    background-color: #282828;
    color: white;
}

footer h2{
    @apply text-white font-medium
}

/* Responsive */
@screen sm {
    #slider-hero::before,
    #page-hero::before  {
        content: "";
        background-image: url("/img/bg/fond-hero.png");
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: -8rem;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 1;
    }

    .containerCardCarre {
        display: grid !important;
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
}
@screen md {
    #slider-hero::before,
    #page-hero::before  {
        content: "";
        background-image: url("/img/bg/fond-hero.png");
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: -14rem;
        z-index: 2;
        width: 100%;
        height: 100%;
        opacity: 1;
    }


    .introActu img {
        @apply h-full w-full;
        aspect-ratio: 1/1;
        object-fit: cover;
    }

}
@screen lg {
    header a:not(.logo):hover:after {
        content: "";
        background-image: url("/img/bg/tiret-hover.png");
        background-repeat: no-repeat;
        position: absolute;
        background-size: contain;
        background-position: bottom;
        bottom: -1.5rem;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    header a {
        @apply text-black relative transition-all inline-block py-0;
        font-size: 1rem
    }

    #slider-hero::before,
    #page-hero::before  {
        content: "";
        background-image: url("/img/bg/fond-hero.png");
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        background-size: initial;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    #vehicule::before {
        content: "";
        background-image: url("/img/bg/trait-vehicule.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 15rem;
        left: -5rem;
        background-position: top left;
        z-index: -1;
        width: 72%;
        height: 100%;
    }

    #presentation h2::after,
    #espace-moto h2::after {
        content: "";
        background-image: url("/img/bg/trait-presentation.png");
        background-repeat: no-repeat;
        position: absolute;
        background-position: top right;
        top: -1rem;
        transform: rotate(180deg);
        left: 104%;
        z-index: 0;
        width: 100%;
        height: 100%;
    }
    #presentation .reverse h2::after,
    #espace-moto  .reverse h2::after{
        content: "";
        background-image: url("/img/bg/trait-presentation.png");
        background-repeat: no-repeat;
        transform: rotate(0deg);
        position: absolute;
        background-position: top right;
        top: 0.75rem;
        right: 104%;
        left: auto;
        z-index: 0;
        width: 100%;
        height: 100%;
    }

    #reprise h2::after {
        content: "";
        background-image: url("/img/bg/trait-reprise.png");
        background-repeat: no-repeat;
        position: absolute;
        background-position: right;
        bottom: 0;
        left: 2rem;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .introActu img {
        @apply h-auto w-full;
        aspect-ratio: unset;
        object-fit: cover;
    }
}
@screen xl {
    header a {
        @apply text-black relative transition-all inline-block py-0;
        font-size: 0.9rem
    }
    #reprise h2::after {
        content: "";
        background-image: url("/img/bg/trait-reprise.png");
        background-repeat: no-repeat;
        position: absolute;
        background-position: right;
        bottom: 0;
        left: 3rem;
        z-index: 2;
        width: 100%;
        height: 100%;
    }

    .containerCardCarre {
        display: grid !important;
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
    }

}

@print {
    footer, header, aside {
        @apply hidden;
    }
}

/* spinner loader */
.lds-dual-ring {
    pointer-events: none;
    display: inline-block;
    height: 6rem;
    width: 6rem;
    opacity: 0;
}
.lds-dual-ring:after {
    content: " ";
    margin: 1rem;
    display: block;
    height: 5rem;
    width: 5rem;
    border-radius: 9999px;
    border-width: 8px;
    border-top-color: rgb(86 123 128 / var(--tw-border-opacity));
    border-right-color: transparent;
    --tw-border-opacity: 1;
    border-bottom-color: rgb(86 123 128 / var(--tw-border-opacity));
    border-left-color: transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
    animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@keyframes lds-dual-ring {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.elements-filters-list.is-loading .lds-dual-ring {
    opacity: 1;
}
.elements-filters-list.is-loading .elements-filters-list-container {
    opacity: 0.25;
}

/** Formulaire Avis **/
.avis {
    max-width: 650px;
}
