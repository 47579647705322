/* Boutique */
.fil-ariane {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem
}
#cadre-contenu {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 91.666667%;
    padding: 1.5rem;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
#produits {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem
}
#produits > div {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 56rem;
    flex-direction: row;
    justify-content: space-between;
    text-align: center
}
#produits > div > a {
    margin: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 91.666667%;
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
#produits > div > a > p {
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}
#produits > div > a:hover > p {
    text-transform: uppercase;
    --tw-text-opacity: 1;
    color: rgba(166, 41, 51, var(--tw-text-opacity))
}
/* Panier */
#panier_etapes {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    display: flex;
    max-width: 48rem;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 2.5rem;
    padding-right: 2.5rem
}
#panier_etapes > div {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem
}
#panier_etapes > div span {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    height: 2rem;
    width: 2rem;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    padding: 0.5rem;
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity))
}
#panier_etapes > div span.actif {
    --tw-bg-opacity: 1;
    background-color: rgba(166, 41, 51, var(--tw-bg-opacity))
}
/* Paiement */
#input-radio-paiement {
    position: relative;
    display: inline-block;
    width: 33.333333%;
    padding: 1.5rem
}
#input-radio-paiement input {
    position: relative;
    display: inline-block
}
#input-radio-paiement input:checked {
    border-width: 2px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgba(166, 41, 51, var(--tw-border-opacity))
}
#input-radio-paiement label {
    position: relative;
    display: inline-block
}